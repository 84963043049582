@font-face {
  font-family: "PP Mori";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("PPMori-Regular.woff2") format("woff2"), url("PPMori-Regular.woff") format("woff");
}
@font-face {
  font-family: "PP Mori";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("PPMori-RegularItalic.woff2") format("woff2"),
    url("PPMori-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "PP Mori";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("PPMori-Bold.woff2") format("woff2"), url("PPMori-Bold.woff") format("woff");
}
@font-face {
  font-family: "PP Mori";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("PPMori-BoldItalic.woff2") format("woff2"), url("PPMori-BoldItalic.woff") format("woff");
}
