/*
  NOTE: If you're adding something here, you're doing it wrong!!
  this is for necessary external css files ONLY!
  all app styling should be added via inline js styles
*/
@import url(@clockwise/design-system/src/styles/reset.css);
@import url(@clockwise/design-system/src/styles/fonts.css);
@import url(@clockwise/design-system/src/styles/mantine.css);
@import url(./tailwind.css);
@import url(./base.css);
@import url(./clock-loader.css);
@import url(./week-scroll.css);
@import url(./tooltip-overrides.css);
@import url(./scrollbar.css);
