@import url(@mantine/core/styles.css);
@import url(@mantine/dates/styles.css);
/*
  Override defaults from @mantine/core/styles.css that set global styles.
  These should match styles in our reset.css file.
 */
body {
  line-height: 1;
  font-size: inherit;
  background-color: transparent;
}
