.__react_component_tooltip {
  border-radius: 4px !important;
  font-size: 12px !important;
  line-height: 22px !important;
  opacity: .9 !important;
  padding: 0 8px !important;
}

.__react_component_tooltip.type-dark {
  background-color: rgb(97, 97, 97) !important;
}

.__react_component_tooltip:after {
  opacity: .9 !important;
}

.__react_component_tooltip.type-dark.place-left:after {
  border-left-color: rgb(97, 97, 97) !important;
}
.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: rgb(97, 97, 97) !important;
}
.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: rgb(97, 97, 97) !important;
}
.__react_component_tooltip.type-dark.place-bottom:after {
  border-bottom-color: rgb(97, 97, 97) !important;
}
