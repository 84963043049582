.overrideForWeekScroll .ReactVirtualized__Grid__innerScrollContainer {
  /* to my knowledge, there's no way to acess the innerScrollContainer directly */
  position: relative;
}

.overrideForWeekScroll .shitty-scroll-hidden-override {
  /* for the life of me, I could not get certain elements to obey this
    gets overriden somewhere in the chain of element generation */
  overflow: hidden !important;
}
