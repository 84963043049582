.cwChromeExtension .cwBoxed {
  position: absolute;
  right: 0;
  height: 100%;
  width: 300px;
  max-width: 300px,
}

#intercom-container {
  visibility: hidden;
}

.intercom-launcher-frame, .intercom-messenger-frame, .intercom-launcher-discovery-frame {
  right: 325px !important;
}

/*
  ~ NAVIGATION TRANSITIONS ~
  Keep these in CSSTransition so the browser manages reflows
  You may be tempted to use Transition instead and manage it through javascript,
  but the performance is worse.
  See: https://github.com/reactjs/react-transition-group/issues/159#issue-250006824
  
  NOTE: Mirror CHROME_MAX_WIDTH found in chrome-wrapper.styles.ts
  CHROME_MAX_WIDTH === 275
*/

/* slide-in-from-left */

.view-transition-in .slide-enter {
  transform: translateX(280px);
  z-index: 1;
}

.view-transition-in .slide-enter.slide-enter-active {
  transform: translateX(0);
  transition: transform 250ms;
}

.view-transition-out .slide-exit {
  transform: translateX(0);
  z-index: 1;
}

.view-transition-out .slide-exit.slide-exit-active {
  transform: translateX(280px);
  transition: transform 250ms;
}

/* slide-up-from-bottom */

.slide-up-enter {
  transform: translateY(20%) scaleX(.8) scaleY(.5);
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}

.slide-up-enter.slide-up-enter-active {
  transform: translateY(0) scaleX(1) scaleY(1);
  opacity: 1;
}

.slide-up-exit {
  transform: translateY(0) scaleX(1) scaleY(1);
  opacity: 1;
  transition: transform 300ms ease-in, opacity 300ms;
}

.slide-up-exit.slide-up-exit-active {
  transform: translateY(20%) scaleX(.8) scaleY(.5);
  opacity: 0;
}

.slide-enter {
  z-index: 1;
}

.slide-exit {
  z-index: 0;
}
