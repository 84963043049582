/* SCROLLBAR TRACK */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
  border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background: rgba(0, 0, 0, 0.15);
}

/* SCROLLBAR THUMB */

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
}

body:hover ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  /* background: rgba(0, 0, 0, 0.2); */
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
